<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="View Announcement" slot="title" link="/helpContent/announcement" />
        <!-- <div>
            <div id="release-heading">
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                        <tr align="left" style="width: 100%">
                            <td style="padding-left: 5px;">
                                <span id="release_date">Expire Date {{ expiresOn }}</span>
                            </td>
                            <td align="right" style="padding-right: 5px;">
                                <span id="version_name" class="div_versionName">{{ noteVersion }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
        <FormRow>
          <!-- style="padding-left:30px;padding-right: 30px;" -->
          <div class="col-sm-12">
        <b><div v-html="noteTitle"> </div></b>
        <div v-html="noteContent" id="note-detail" style="margin-top:20px;margin-bottom: 40px;"></div>
    </div>
        </FormRow>
  
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
    </FormWrapper>
  </template>
  
  <script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DateUtil from 'Utils/dateUtil';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getAnnouncementList} from '../api';
    export default {
        name: "View",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle
        },
        data() {
            return {
              filtering: {
        },
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
                noteContent: "",
                noteTitle: "",
                noteVersion: "",
                expiresOn: ""
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const { key, order } = this.sorting;
          const { perPage, page } = this.pagination;
  
          const filters = {};
          filters.id = (this.$route.params.id)?atob(this.$route.params.id):0;
          const data = {
            filterjson: {
              filter: [filters],
              sort: [{ key, order }],
              paging: [
                {
                  startIndex: page,
                  pagesize: perPage,
                },
              ],
            },
          };
                    getAnnouncementList(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.noteContent = response.data[0].Body;
                this.noteTitle = response.data[0].Title;
              //   this.noteVersion = response.data[0].VersionName;
                this.expiresOn =response.data[0].ExpiresOn;
            },
        }
    }
  </script>
  <style lang="scss">
    #release-heading{
        background-color: #eeedf2;
        height: 21px;
        font: white;
        color: black;
        padding-top: 4px;
        font-weight: bold;
        width: 100%;
        vertical-align: middle;
    }
  
    ol{
        padding-left: 5%;
    }
  </style>